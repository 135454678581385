/* Global Styles */
/* Put those styles that can't applied via props */

/* Global styles for react-datepicker ---------------*/
.react-datepicker {
  z-index: 9999 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.chakra-input__right-element {
  z-index: 0 !important;
}

.css-qhjqy9 {
  padding: 1.4em;
}

/* --------------------------------------------------*/

/* Keyframes --------------------------------------- */
@keyframes phone-ring {
  0% {
    transform: scale(1);
  }

  15% {
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.4);
  }

  25% {
    box-shadow: 0 0 0 5px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }

  25% {
    box-shadow: 0 0 0 6px rgba(66, 153, 225, 0.4),
      0 0 0 8px rgba(66, 153, 225, 0.2);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #c2c2c2;
}

/* --------------------------------------------------*/
.logo-icon {
  color: gray;
}

/* feedback response rating background color */
.rating-tag {
  display: block;
  text-align: center;
  max-width: 3rem;
  font-weight: 500;
  line-height: 2;
  min-height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.875rem;
  color: #fff;
  border-radius: 9999px;
}

#tag-background-color-red {
  background-color: #f56565 !important;
}

#tag-background-color-orange {
  background-color: #ed8936 !important;
}

#tag-background-color-green {
  background-color: #68d391 !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

.custom-select-container {
  position: relative;
  box-sizing: border-box;
}

.outer-line-span {
  z-index: 9999;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}

.all-content-container {
  align-items: center;
  background-color: #ffffff;
  border-color: inherit;
  border-radius: 0.125rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 1px 0px #eff0f6;
  cursor: inherit;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 42px;
  outline: 0 !important;
  position: relative;
  transition: all 0.35s ease-in-out;
  box-sizing: border-box;
  opacity: 1;
}

.text-placeholder-and-input-field-container {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 0 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.text-placeholder {
  color: #000000;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}

.input-field-inner-container {
  box-sizing: content-box;
  width: 100%;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
}

.input-field {
  width: 100%;
  margin-left: 4px;
  outline: none !important;
}

.input-field::placeholder {
  color: #000000;
}

.seperator-arrow-outer-container {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}

.line-seperator {
  align-self: stretch;
  background-color: #cbd5e0;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.arrow-inner-container {
  color: #000000;
  display: flex;
  padding: 7px 8px;
  box-sizing: border-box;
  transition-property: color;
  transition-duration: 150ms;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.arrow-svg {
  display: inline-block;
  fill: #718096;
  line-height: 1;
  stroke-width: 0;
}

.modal-box {
  width: 100%;
  z-index: 9999;
  position: absolute;
  margin-top: 6px;
  padding-top: 4px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #ddd;
  overflow-y: auto;
  max-height: 330px;
}

.list-item {
  padding-left: 10px;
  font-size: 13px;
  font-weight: 300;
  height: 36px;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  text-wrap: nowrap;
  overflow-x: hidden;
}

.list-item-disabled {
  opacity: 0.4;
}

.list-item:hover {
  background-color: #e2e8f0;
  opacity: 0.9;
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.tickets-image~.hidden-icon {
  display: none;
}

.hidden-icon:hover {
  display: block !important;
}

.tickets-image:hover~.hidden-icon {
  display: block;
}

.primary-accent-color {
  accent-color: #5a9f7e;
}

.message-date-header:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  align-self: center;
  background: #f4f5f9;
  margin-right: 6px;
}

.message-date-header:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  align-self: center;
  background: #f4f5f9;
  margin-left: 6px;
}

.date-picker-two-months .react-datepicker {
  width: max-content;
}

@media only screen and (max-width: 750px) {
  .date-picker-two-months .react-datepicker {
    width: min-content;
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.w-70 {
  width: 70%;
}

.divider {
  border-top: 1px solid #5b5b60;
}

.page-break-inside-avoid {
  page-break-inside: avoid;
}

.mt-4 {
  margin-top: 4px;
}

.text-align-right {
  float: right;
}

.fade {
  color: rgb(172, 170, 170);
}

/* Show scroll in ticket board */
.show-scroll>.ps__rail-x,
.show-scroll>.ps__rail-y,
.show-scroll>.ps__rail-x,
.show-scroll>.ps__rail-y,
.show-scroll.ps--scrolling-x>.ps__rail-x,
.show-scroll.ps--scrolling-y>.ps__rail-y {
  opacity: 0.6 !important;
}

/* Global react select styles */
.react-select__indicator {
  background-color: white !important;
  padding: 0 8px !important;
}

.react-select__indicator>svg {
  width: 18px;
  height: 18px;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu-list {
  z-index: 9999 !important;
}

.no-border th,
.no-border td {
  border: none;
  /* Remove borders */
}

.country-select__control {
  margin: 12px;
  width: 93% !important;
}

.country-select__menu {
  margin-top: 0px !important;
}

.country-select__menu-list {
  border-top: none;
}